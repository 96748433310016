<template>
  <div>
    <!-- 外汇-课程交易-资金流水 -->
    <div style="margin-top:20px">币种类型：
      <el-select v-model="accountType" placeholder="请选择币种" clearable style="width: 280px;" size="mini"
        @change="courseFunds">
        <el-option v-for="item in currencyData" :key="item.id" :label="item.name" :value="item.abbr" />
      </el-select>

      <!-- 查询历史 -->
      <span style="padding-left: 50px;"> 查询历史 &nbsp
        <el-tooltip class="item" effect="dark" content="默认查询当日" placement="top">
          <i style="color: #faad14" class="el-icon-warning"></i>
        </el-tooltip>&nbsp
      </span>
      <el-date-picker size="mini" v-model="historicalTime" type="daterange" range-separator="至" start-placeholder="开始日期"
        value-format="YYYY-MM-DD HH:mm:ss" end-placeholder="结束日期" @change="courseFunds">
      </el-date-picker>
      <el-dropdown size="mini" style="margin-left: 10px" split-button type="primary">
        <span>导出历史</span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>导出全部历史</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <!--成交详情表 -->
    <div style="margin-top:20px" v-loading="loading">
      <el-table :data="forexOrderVOList" stripe style="width: 100%"
        :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
        <el-table-column label="交易时间" prop="createTime" width="230" />
        <el-table-column label="币种类型" prop="currency" />
        <el-table-column label="空闲资金" prop="currencyAmounts" />
        <el-table-column label="交易金额" prop="forexAmount" />
        <el-table-column label="交易汇率" prop="exchangeRates" />
        <el-table-column label="剩余资金" prop="currencyBalances" />
      </el-table>
    </div>

    <!-- 分页 -->
    <div class="demo-pagination-block">
      <el-pagination :current-page="currentPage" :page-size="pageSize" :page-sizes="[5, 10, 15, 20]"
        layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="sizeChange"
        @current-change="currentChange" />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue';
import { entrustedOrders, findCurrency, courseFundss } from './CourseFundFlowMethods'
export default {
  setup() {
    onMounted(() => {
      entrustedOrders(data)
      findCurrency(data)
    })
    let data = reactive({
      loading: true,
      historicalTime: '',
      accountType: '',//币种类型
      currentPage: 1,//当前页面为1
      pageSize: 10,//每页业条数
      total: 0,//条目总数
      currencyData: [],//货币类型
      forexOrderVOList: [],//所有交易订单
    })
    let sizeChange = (val) => {
      data.pageSize = val
      entrustedOrders(data)
    }
    let currentChange = (val) => {
      data.currentPage = val
      entrustedOrders(data)
    }
    let courseFunds = () => {
      courseFundss(data)
    }
    return {
      ...toRefs(data),
      sizeChange,
      currentChange,
      courseFunds
    }
  },
}
</script>
<style lang="less" scoped>
.demo-pagination-block {
  margin-top: 20px;
  float: right
}
</style>