import axios from '@/axios/globalaxios'

// 查找外汇订单的资金流水
export const findForexFlowFunds  = (data) => {
  return axios({
    url : '/tradelab/forexOrder/findForexFlowFunds',
    method: 'post',
    data:data
  })
}

//查找所有货币类型
export const findCurrencyType = () => {
  return axios({
    url: "/tradelab/forexOrder/findCurrencyType",
    method: 'get',
  })
}